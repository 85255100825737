<template>
  <div class="content">
    <div>
      <a-row :gutter="16">
        <a-col :span="7">
          <a-input v-model="form.serialNumber" :placeholder="$t('学习模块.学习名称')" size="large" />
        </a-col>
        <a-col :span="7">
          <!--<a-input v-model="form.type" :placeholder="$t('学习模块.类型')" size="large" />-->
          <a-select
            v-model="form.type"
            style="width: 100%"
            :placeholder="$t('学习模块.类型')" size="large"
          >
            <a-select-option value="Clinical Study">Clinical Study</a-select-option>
            <a-select-option value="Virtual Study">Virtual Study</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="7">
          <a-input v-model="form.studyName" :placeholder="$t('学习模块.序列号')" size="large" />
        </a-col>
        <a-col :span="3">
          <a-button  class="btn">{{$t('学习模块.清空')}}</a-button>
        </a-col>
      </a-row>
    </div>
    <div style="margin-top: 16px">
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['studies:studies:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['studies:studies:edit']">
             <a-icon type="edit" />{{$t('通用.按钮.修改')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['studies:studies:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['studies:studies:remove']">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <!--<a-pagination-->
        <!--class="ant-table-pagination"-->
        <!--show-size-changer-->
        <!--show-quick-jumper-->
        <!--:pageSizeOptions="pageSizeOptions"-->
        <!--:current="queryParam.pageNum"-->
        <!--:total="total"-->
        <!--:page-size="queryParam.pageSize"-->
        <!--:showTotal="total => getTotal(total)"-->
        <!--@showSizeChange="onShowSizeChange"-->
        <!--@change="changeSize"-->
      <!--/>-->
    </div>
  </div>
</template>

<script>
  import { pageStudies,listStudies, delStudies } from '@/api/studies/studies'
  import CustomDictTag from "@/components/DictCustomTag";
  import {mapGetters} from 'vuex'
  import moment from 'moment';
  import { tableMixin } from '@/store/table-mixin'

export default {
  data() {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        // clinicianId: undefined,
        // clinicalId: undefined,
        // studyName: undefined,
        // type: undefined,
        // patientName: undefined,
        // patientAge: undefined,
        // patientNumber: undefined,
        // pageNum: 1,
        // pageSize: 10
        serialNumber:"",
	      studyName:"",
	      type:"",
      },

      form: {},
      columns: [
        {
          title: this.$t('学习模块.学习名称'),
          dataIndex: 'studyName',
          key: 'studyName',
        },
        {
          title: this.$t('学习模块.类型'),
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: this.$t('学习模块.传感器'),
          dataIndex: 'sensors',
          key: 'sensors',
        },
      ],
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
},
  watch: {
  },
  methods: {
    moment,
      getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询学习计划列表 */
    getList () {
      this.loading = true
      pageStudies(this.queryParam).then(response => {
      this.list = response.data.records
      // this.total = response.data.total
      this.loading = false
    })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        clinicianId: undefined,
        clinicalId: undefined,
        studyName: undefined,
        type: undefined,
        patientName: undefined,
        patientAge: undefined,
        patientNumber: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        if (this.queryParam.sortField==='createTime') {
          this.queryParam.sortField= 'id'
        }
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids = []
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('通用.文本.确认删除所选中数据'),
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delStudies(data)
            .then(() => {
            that.onSelectChange([], [])
          that.getList()
          const message = that.$t('通用.文本.删除成功')
          that.$message.success(
            message,
            3
          )
        })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('studies/studies/export',
            that.queryParam
            , `学习计划_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }


}
</script>

<style scoped lang="less">
.content {
  .btn {
    // width: 108px;
    height: 38px;
    align-items: center;
    background-color: #7f62ad;
    border-color: #7f62ad;
    box-shadow: none !important;
    display: flex;
    // white-space: pre;
    color: #fff;
    border-radius: 0.25rem;
    font-weight: 500;
    font-size: 16px;
  }
}
</style>